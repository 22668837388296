import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {Alert, MenuItem, Select, Snackbar, TextField} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {AdminProjectContext} from "../../../contexts/AdminProjectContext";
import useAxios from "../../../hooks/useAxios";
import {UserInterface} from "../../../interface/User.interface";
import {ADMIN_VIEWS, NEW_PARTICIPANT_URL} from "../../../utils/constant";
import {AdminUserContext} from "../../../contexts/AdminUserContext";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {CommonContext} from "../../../contexts/CommonContext";
import {ShareConfiguration} from "../../../interface/UserProject.interface";
import {map} from "lodash";

export default function AdminAddProjectParticipantsModal(props: { openModel: boolean, setOpenModal: Function }) {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 700,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 16,
    };


    //declarations

    const {selectedProject} = useContext(AdminProjectContext)
    const {users} = useContext(AdminUserContext)
    const {setNeedToRefreshData, updateSelectedMenu} = useContext(CommonContext)
    const [share, setShare] = useState<number>(1)
    const [withdrawalRanks, setWithdrawalRanks] = useState<(number) []>([])
    const {putWithAxios} = useAxios()
    const [selectedUser, setSelectedUser] = useState<UserInterface>()
    const [globalError, setGlobalError] = useState('')
    const [globalInfo, setGlobalInfo] = useState('')


    const actualProjectParticipant = selectedProject?.Users
    const nonParticipants = users
        .filter((user) => !(actualProjectParticipant?.map(it => it.uuid).includes(user.uuid)))
        .filter((it) => it.role?.toUpperCase() !== "ADMIN")


    // functions

    const onClose = () => props.setOpenModal(false);

    const handleClose = (event: React.MouseEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const addNewParticipant = () => {

        putWithAxios(NEW_PARTICIPANT_URL(selectedProject?.uuid!, selectedUser!.uuid, share, withdrawalRanks), {})
            .then((res) => {
                console.log(res)
                setGlobalError("")
                setGlobalInfo(` ${selectedUser?.firstname} ajouté avec succès`)
                setNeedToRefreshData(true)
                onClose();
            }).catch((err) => {
            console.log(err)
            setGlobalError(err.message)
        })
    }


    // useEffects
    useEffect(() => {
        // share can be increased or decreased, I want to adjust the withdrawalRanks
        // let withdrawalContent: number[] = []
        // if (withdrawalRanks.length < share) {
        //     withdrawalContent = [...withdrawalRanks, ...Array(share - withdrawalRanks.length).fill(0)]
        // } else {
        //     withdrawalContent = withdrawalRanks.slice(0, share)
        // }
        setWithdrawalRanks([...Array(share)].map(it => 0))
        console.log("****", withdrawalRanks)
    }, [share])


    return (
        <Modal
            open={props.openModel}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={style}
                 className={"d-flex py-5 bg-white d-flex justify-content-start flex-row"}>

                <IconButton onClick={onClose} className={"ms-3"} style={{width: '20px', height: '20px'}}>
                    <Close/>
                </IconButton>

                {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}

                <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                          message={globalInfo}></Snackbar>
                {selectedProject &&

                  <div className={"mt-2 d-flex flex-column "}>

                    <p className={"text_weight_700_20_px"}>Nouvel Adhésion</p>
                    <p className={""}>Vous vous apprêtez à ajouter un utilisateur au projet {selectedProject.name}</p>


                    <Select className={"m-2"} required={true} variant="outlined" label={"Nom"}
                            value={selectedUser?.uuid}
                            onChange={(e, newValue) => {
                                setSelectedUser(nonParticipants.filter((user) => user.uuid === e.target.value)[0])
                                // console.log(e.target.value)
                            }}>
                        {nonParticipants.map((user) =>
                            <MenuItem key={user.uuid}
                                      value={user.uuid}>{user.lastname} {user.firstname}</MenuItem>
                        )}
                    </Select>
                      {/*//Add share field*/}

                      {selectedUser &&
                        <div>
                          <TextField type={"number"} defaultValue={1} className={"m-2"} required={true} value={share}
                                     variant="outlined"
                                     label={"Nbr de Parts"}
                                     error={share <= 0}
                                     inputProps={{min: 1}}
                                     helperText={share <= 0 ? "Nombre de part invalide" : ""}
                                     onChange={e => setShare(parseInt(e.target.value))}>

                          </TextField>

                            {/*// if share = 2 , I want to display 2 fields to enter the rank of each share*/}
                            {share > 0 && [...Array(share)].map((_, i) => {
                                return <TextField key={i} type={"number"} defaultValue={0} className={"m-2"}
                                                  required={true} variant="outlined"
                                                  error={withdrawalRanks[i] <= 0}
                                                  inputProps={{min: 0}}
                                                  helperText={withdrawalRanks[i] <= 0 ? "Position invalide" : ""}
                                                  label={`Position ${i + 1}`} value={withdrawalRanks[i]}
                                                  onChange={e => {
                                                      withdrawalRanks[i] = parseInt(e.target.value)
                                                      setWithdrawalRanks([...withdrawalRanks])
                                                  }}></TextField>
                            })}

                          <div className={"d-flex justify-content-end"}>
                            <Button className={"m-2 p-2"}
                                    variant={"outlined"}
                                    disabled={!selectedUser || share <= 0 || withdrawalRanks.length == 0
                                        || withdrawalRanks.length !== share || withdrawalRanks.includes(0)}
                                    color={"success"}
                                    onClick={() => addNewParticipant()}
                                    size={"small"}> Ajouter
                            </Button>
                          </div>
                        </div>}
                  </div>}
            </Box>

        </Modal>
    );
}
