import {Close} from "@mui/icons-material";
import {Alert, Snackbar, TextField} from "@mui/material";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import Modal from '@mui/material/Modal';
import 'bootstrap/dist/css/bootstrap.css';
import * as React from 'react';
import {MouseEventHandler, useContext, useEffect, useState} from "react";
import {CommonContext} from "../../../contexts/CommonContext";
import useAxios from "../../../hooks/useAxios";
import {User} from '../../../Models/User';
import {EMAIL_REG_EXPRESSION, REMOVE_PARTICIPANT_URL, UPDATE_USER_URL} from "../../../utils/constant";
import {AdminProjectContext} from "../../../contexts/AdminProjectContext";


export default function AdminParticipantSettingModal(props: {
    openModal: boolean,
    setOpenModal: Function,
    participant?: User
}) {

    const style = {
        position: 'absolute' as 'absolute',
        width: '500px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        boxShadow: 24,
    };

    const onClose = () => props.setOpenModal(false);

    const {selectedProject} = useContext(AdminProjectContext)

    const handleClose = (event: React.MouseEvent, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            onClose();
        }
    };

    const {setNeedToRefreshData} = useContext(CommonContext)
    const {deleteWithAxios} = useAxios()

    const [globalError, setGlobalError] = useState('')
    const [globalInfo, setGlobalInfo] = useState('')
    const [formData, setFormData] = useState<{ confirmation: string }>({confirmation: ''})
    // const [validationErrors, setValidationError] = useState({email: '', firstname: '', confirmation: ''})

    const handleRemoveParticipant = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        e.stopPropagation()
        deleteWithAxios(REMOVE_PARTICIPANT_URL(selectedProject?.uuid!!, props.participant?.uuid!!))
            .then((res) => {
                setGlobalError("")
                setGlobalInfo("Adhérent retiré avec succès")
                setNeedToRefreshData(true)
                onClose()

            }).catch((err) => {
            console.log(err)
            setGlobalError(err.message)
        })
    }

    return (
        <Modal
            open={props.openModal}
            onClose={handleClose}
            aria-labelledby="modal-mod-title"
            aria-describedby="modal-mod-description"
        >

            <Box sx={style}
                 className={"row d-flex bg-white d-flex justify-content-start col-md-12 p-3"}>
                <IconButton onClick={onClose} className={"ms-2"} style={{width: '20px', height: '20px'}}>
                    <Close/>
                </IconButton>
                {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}
                <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                          message={globalInfo}></Snackbar>
                <form className={"d-flex flex-column col-sm-12 col-md-12"}>

                    <div className={"m-2 text-left"}>
                        <h4 className={"mb-4"}>Confirmez la suppression de l'adhérent</h4>
                        <span className={"mr-2"}>Participant: </span><span
                        className={"text_weight_700_20_px"}>{`${props.participant?.lastname} ${props.participant?.firstname}`}</span><br/>

                        <div className={""}><span className={"mr-2"}>Nombre de Parts: </span><span
                            className={"text_weight_700_20_px"}>{props.participant?.UserProject?.share}</span>
                        </div>
                        <TextField className={"mb-1"} required={true} variant="outlined"
                                   label={"Confirmez la suppression"}
                                   placeholder={"CONFIRMER LA SUPPRESSION"}
                                   helperText={"Tapez 'CONFIRMER LA SUPPRESSION' pour confirmer la suppression"}
                                   value={formData.confirmation}
                                   onChange={(e) => setFormData({confirmation: e.target.value.toString()})}
                        ></TextField>
                    </div>

                    <div className={"d-flex justify-content-end mb-2"}>
                        <button className={"btn btn-dark mr-2"}
                                style={{"backgroundColor": "#cc4040", borderColor: 'grey'}}
                                disabled={formData.confirmation !== "CONFIRMER LA SUPPRESSION"}
                                onClick={handleRemoveParticipant}>
                            Retirer le participant
                        </button>

                        <button className={"btn btn-dark me-2"} style={{"backgroundColor": "#000", borderColor: 'grey'}}
                            // onClick={() => props.selectedMenu(ADMIN_VIEWS.NEW_PARTICIPANT_VIEW)}
                                onClick={onClose}>Fermer
                        </button>
                    </div>


                </form>

            </Box>

        </Modal>
    );
}
