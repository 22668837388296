import {
    Alert,
    Button,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    TextField
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { AdminProjectContext } from "../../contexts/AdminProjectContext";
import { AdminUserContext } from "../../contexts/AdminUserContext";
import { CommonContext } from "../../contexts/CommonContext";
import useAxios from "../../hooks/useAxios";
import { TransactionIn } from "../../interface/TransactionIn.interface";
import {
    ADMIN_VIEWS,
    NEW_CASH_IN_URL,
    SHORT_MONTHS
} from "../../utils/constant";

export default function NewCashInForm(props: { selectedMenu: Function, }) {
    // selectedProject: Project,
    //     remainingCatch: number,
    //
    //
    // console.log(props.globalUserList)
    const {postWithAxios} = useAxios()
    const {selectedProjectParticipant, } = useContext(AdminUserContext)
    const {selectedProject } = useContext(AdminProjectContext)
    const {setNeedToRefreshData } = useContext(CommonContext)

    const [transactionIn, setTransactionIn] = useState<TransactionIn>()

    const [remainingAmount, setRemainingAmount] = useState(Number.MAX_VALUE)

    const [globalError, setGlobalError] = useState('')
    const [globalInfo, setGlobalInfo] = useState('')
    const [formErrors, setFormErrors] = useState(
        {
            userUuid: '',
            projectLineUuid: '',
            amount: '',
        })


    useEffect(() => {
        // console.log(project.endDate)
        if (transactionIn) setFormErrors(
            {
                userUuid: selectedProjectParticipant?.uuid ? '' : "Selectionnez un adhérant",
                projectLineUuid: transactionIn?.ProjectLineUuid ? '' : "Selectionnez un mois",
                amount: ((!transactionIn.amount || isNaN(transactionIn.amount) || transactionIn.amount <= 0) ? "Entrez un montant" :
                    (transactionIn.amount > remainingAmount ? 'Montant suppérieur au total restant à payer' : ''))
            })
    }, [remainingAmount, selectedProjectParticipant?.uuid, transactionIn]);

    useEffect(() => {
        if (!selectedProject) setGlobalError("Pas de projet selectionné")
        else if (!selectedProjectParticipant?.uuid) setGlobalError("Pas d'adhérant sélectionné")
        else setGlobalError("")

        if (selectedProject && selectedProjectParticipant && transactionIn?.ProjectLineUuid) setRemainingAmount((selectedProject.contribution * (selectedProjectParticipant.UserProject?.share || 1)) - selectedProjectParticipant.getUserTotalPayedAmountForAMonth(transactionIn?.ProjectLineUuid!, selectedProject))

    }, [transactionIn,selectedProject, selectedProjectParticipant]);

    const addCashIn = () => {
        console.log("**** in add Cash in ***")
        // console.log(props.selectedProject)
        postWithAxios(NEW_CASH_IN_URL,
            {
                projectLineUuid: transactionIn?.ProjectLineUuid,
                userUuid: selectedProjectParticipant!.uuid,
                amount: `${transactionIn!.amount!}`,
                projectUuid: selectedProject!.uuid
            })
            .then((res) => {
                console.log(res)
                setGlobalError("")
                setGlobalInfo(` Encaissement éffectué avec succès`)
                setNeedToRefreshData(true)
                props.selectedMenu(ADMIN_VIEWS.DASHBOARD_VIEW)
                // setProject(defaultProject)

            }).catch((err) => {
            console.log(err)
            console.log("*** lvl 2 ***")
            setGlobalError(err.message)
        })
    }

    return (
        <div
            className={"row py-2 col-sm-12 col-md-12 col-lg-8 col-xl-9 bg-white d-flex justify-content-start flex-row"}>

            {globalError !== '' && <Alert severity={"error"}>{globalError}</Alert>}

            <p className={"text_weight_700_20_px  m-2"}>Encaissement</p>


            <Snackbar open={globalInfo !== ''} autoHideDuration={6000} onClose={() => setGlobalInfo('')}
                      message={globalInfo}></Snackbar>

                {selectedProject && selectedProject.ProjectLines.length > 0 && selectedProject.Users.length > 0 &&
                  <form className={"d-flex flex-column  col-sm-12 col-md-6"}>

                    <div className={"m-2 d-flex justify-content-between"}>
                      <span>Participant : <strong>{`${selectedProjectParticipant?.lastname} ${selectedProjectParticipant?.firstname}`}</strong></span>
                      <span>Montant total : <strong>{`${(selectedProject.contribution * (selectedProjectParticipant?.UserProject?.share || 1))} ${selectedProject.getCurrencySymbol()}`}</strong></span>
                    </div>

                      {transactionIn?.ProjectLineUuid && selectedProjectParticipant &&
                        <div className={"m-2 d-flex justify-content-between"}>

                                <span>Payé :
                                     <strong>
                                        {` ${selectedProjectParticipant.getUserTotalPayedAmountForAMonth(transactionIn.ProjectLineUuid, selectedProject)}`}
                                    </strong>
                                </span>

                          <span>Reste à payé : <strong>{`${remainingAmount} €`}</strong></span>
                        </div>}

                    <InputLabel className={"m-2"} id="month_select_label">Mois concerné</InputLabel>

                    <Select className={"m-2"} required={true} variant="outlined" label={"Mois"}
                        // value={selectedUser.uuid}
                            onChange={(e) => {
                                setTransactionIn({...transactionIn, ProjectLineUuid: e.target.value as string})
                                console.log(e.target.value)
                            }}>

                        {selectedProject.ProjectLines.map((pl) =>
                            <MenuItem key={pl.uuid}
                                      value={pl.uuid}>{SHORT_MONTHS[pl.month - 1]} {pl.year}</MenuItem>
                        )}

                    </Select>
                    <TextField className={"m-2"} type={"text"}
                               label={"Montant"}
                               value={transactionIn?.amount}
                               error={formErrors.amount !== ""}
                               helperText={formErrors.amount}
                               onChange={(e) => {
                                   setTransactionIn({
                                       ...transactionIn,
                                       amount: isNaN(parseFloat(e.target.value)) ? 0 : parseFloat(e.target.value)
                                   })
                                   console.log(e.target.value)
                               }}>
                    </TextField>
                    <Button className={"m-2 p-2"}
                            variant={"outlined"}
                            disabled={Object.values(formErrors).some((it) => it !== '')}
                            color={"success"}
                            onClick={() => addCashIn()}
                            size={"small"}> Valider </Button>
                  </form>}
        </div>
    );
}
