import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/LoginContext";
import { UserProjectContext } from "../../contexts/UserProjectContext";
import useAxios from "../../hooks/useAxios";
import { Project } from "../../Models/Project";
import { User } from "../../Models/User";
import { USER_PROJECT_URL } from "../../utils/constant";
import { ContainerFluid } from "../ContainerFluid";
import { UserProjectDetailsView } from "./UserProjectDetailsView";
import { UserActiveProjectNextContributionView } from "./UserActiveProjectNextContributionView";
import { UserActiveProjectPastContributionView } from "./UserActiveProjectPastContributionView";

export const UserHome = () => {

    const {currentUser, setCurrentUser} = useContext(LoginContext)
    const [selectedProject, setSelectedProject] = useState<Project>()
    const [projects,setProjects] = useState<Project[]>()



    const {getWithAxios} = useAxios()


    // useEffect hook declaration


    useEffect(() => {
        getWithAxios(USER_PROJECT_URL)
            .then((res) => {
                console.log("****** in fetch user's projects *********")
                console.log(res)
                setProjects(res.Projects.map( (p:any)  => new Project({...p}) ))
                setCurrentUser(new User({
                    ...res,
                    tokens: currentUser.tokens,
                    projects: res.Projects?.reverse() ,
                    defaultProject: (res.Projects && res.Projects.length > 0) ? new Project({...res.Projects[0]}) : undefined
                }))
                if (res.Projects.length > 0) setSelectedProject(new Project({...res.Projects[0]}))

            })
            .catch((err) => {
                console.log("*** error lv: 2 ***")
                console.log(err)
                if (err.response) console.log(err.response.data)
            })

    }, []);





    const userProjectContextValues = {
        projects: currentUser.projects,
        updateProjects:(value:Project[])=>setProjects(value),
        selectedProject,
        updateSelectedProject:(value:Project) => setSelectedProject(value),
    }

    const handleOnProjectSelection = (e:SelectChangeEvent) => {
        console.log("***")
        console.log(projects?.filter((p) => p.uuid === e.target.value.toString())[0])
        setSelectedProject(projects?.filter((p) => p.uuid === e.target.value.toString())[0])
    }


    return (
        <UserProjectContext.Provider value={userProjectContextValues}>
            <ContainerFluid>
                {/*row 1*/}
                {selectedProject &&
                  <div className={"row bg-white shadow-sm rounded justify-content-between my-4 mx-4 px-4 py-4"}>
                      {/*    /!*col 1_1*!/*/}
                      {selectedProject &&
                        <div className={"mb-2"}>
                          <Select value={selectedProject.uuid} label={"Projet"} onChange={handleOnProjectSelection}
                                  className={"text_weight_700_20_px"}>
                              {projects?.map((project) => <MenuItem key={project.uuid} value={project.uuid}
                                                                    className={"select"}
                              >{project.name}</MenuItem>)}
                          </Select>
                        </div>
                      }

                    <UserProjectDetailsView/>
                    <UserActiveProjectNextContributionView/>
                    <UserActiveProjectPastContributionView/>


                  </div>}

            </ContainerFluid>
        </UserProjectContext.Provider>
    )
}

