import axios from "axios";
import { AxiosRequestConfig } from "axios/index";
import { useCallback, useContext, useMemo } from "react";
import { User } from "../Models/User";
import { LoginContext } from "../contexts/LoginContext";
import { defaultUser } from "../utils/constant";

export default function useAxios() {
    const {currentUser, setCurrentUser} = useContext(LoginContext)
    const headers = useMemo(
        ()=>({Authorization: `Bearer ${currentUser.tokens?.accessToken}`}),
        [currentUser]
    )
    const _call = useCallback(async (config: AxiosRequestConfig) => {
        return axios.request(config)
            .then((res) => {
                // console.log(res)
                if (res) return res.data
                return null
            }).catch((err) => {
                console.log("*** axios error : lv1 ***")
                console.log(err)
                // logout if token has expired
                if (err.response && err.response.status == 401) setCurrentUser(new User(defaultUser))
                if (err.response && [404,400,403].includes(err.response.status)) throw Error(err.response.data?.message)
                if ((err.response))throw Error(err.response.data?.message)
            });
    },[])

    const postWithAxios = useCallback(async (url: string, data: object,) => {
        return _call({method: "POST", url, data, headers})
    }, [_call, headers])

    const putWithAxios = useCallback(async (url: string, data: object,) => {
        return _call({method: "PUT", url, data, headers})
    }, [_call, headers])

    const getWithAxios = useCallback(async (url: string,) => {
        return _call({method: "GET", url, headers})
    }, [_call, headers])

    const deleteWithAxios = useCallback(async (url: string,) => {
        return _call({method: "DELETE", url, headers})
    }, [_call, headers])

    return {getWithAxios, postWithAxios, putWithAxios, deleteWithAxios}

}