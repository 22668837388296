import {InfoRounded, AccountCircle, Logout} from '@mui/icons-material';
import {User} from "../Models/User";
import {useContext} from "react";
import {LoginContext} from "../contexts/LoginContext";
import {defaultUser} from "../utils/constant";
import AccountMenu from "./commun/menu/AccountMenu";
import * as React from "react";

export function Navbar(props: { backgroundColor: string }) {
    const navClasses = `navbar navbar-expand-lg navbar-light shadow-sm ${props.backgroundColor}`
    const {currentUser,setCurrentUser} = useContext(LoginContext)


    const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
        localStorage.removeItem("currentUser")
        setCurrentUser(new User(defaultUser))
    }
    return (
        <nav className={navClasses}>
            <div className="container-fluid py-2">
                <div className="d-flex justify-content-center align-content-center">
                    <a className="navbar-brand" href="#">
                        <img src={"images/tas.jpeg"} alt={"logo"} width={"33px"} height={"33px"}/>
                    </a>
                    <div style={{
                        color: '#000000',
                        fontSize: 22,
                        fontFamily: 'Shrikhand',
                        fontWeight: '400',
                        wordWrap: 'break-word'
                    }} className="d-flex align-items-center">T.A.S
                    </div>

                </div>

                <AccountMenu currentUser={currentUser} handleLogout={handleLogout}/>

                {/*<button*/}
                {/*    className="navbar-toggler"*/}
                {/*    type="button"*/}
                {/*    data-bs-toggle="collapse"*/}
                {/*    data-bs-target="#navbarNav"*/}
                {/*    aria-controls="navbarNav"*/}
                {/*    aria-expanded="false"*/}
                {/*    aria-label="Toggle navigation"*/}
                {/*>*/}
                {/*    <span className="navbar-toggler-icon"></span>*/}
                {/*</button>*/}
                {/*<div className="collapse navbar-collapse justify-content-end" id="navbarNav">*/}
                {/*    <ul className="navbar-nav">*/}
                {/*        <li className="nav-item text-end">*/}
                {/*            <a className="nav-link  fw-bold" href="#" style={{*/}
                {/*                fontSize: "14px", fontWeight: 400*/}
                {/*            }}>*/}
                {/*                <AccountCircle/> {currentUser.firstname + " " + currentUser.confirmation.slice(0, 1).toUpperCase()}.*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item text-end">*/}
                {/*            <a className="nav-link  fw-bold" href="#" style={{*/}
                {/*                fontSize: "14px", fontWeight: 400*/}
                {/*            }}>*/}
                {/*                <InfoRounded/>A propos*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*        <li className="nav-item text-end" onClick={handleLogout}>*/}
                {/*            <a className="nav-link  fw-bold" href="#" style={{*/}
                {/*                fontSize: "14px", fontWeight: 400*/}
                {/*            }}>*/}
                {/*                <Logout/>*/}
                {/*            </a>*/}
                {/*        </li>*/}
                {/*    </ul>*/}
                {/*</div>*/}
            </div>
        </nav>

    );
}
