
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useContext, useEffect, useState } from "react";
import { LoginContext } from "../../contexts/LoginContext";
import { UserProjectContext } from "../../contexts/UserProjectContext";
import { TransactionIn } from "../../interface/TransactionIn.interface";
import { SHORT_MONTHS } from "../../utils/constant";
import {User} from "../../Models/User";


export function UserActiveProjectNextContributionView() {

    const { selectedProject } = useContext(UserProjectContext)
    let { currentUser } = useContext(LoginContext)
    const [nextContributions, setNextContributions] =
        useState<{
            uuid: string;
            amount: string;
            month: string;
        }[]>([])

    const getTotalTransactionAmount = (transactions: TransactionIn[]) => {
        return transactions.reduce((previousValue, currentValue) =>
            previousValue + (currentValue.amount || 0), 0)
    }

    useEffect(() => {

        const userProject  = currentUser.projects.filter( it => it.uuid == selectedProject?.uuid)[0].UserProject;
        const nonClosedTrs = currentUser.getNonClosedTransactionsFor(selectedProject)

        setNextContributions(nonClosedTrs.map(it =>
        ({
            uuid: it.uuid,
            amount: `${((selectedProject?.contribution || 0) * (userProject!!.share || 0) - getTotalTransactionAmount(it.TransactionIns))} ${selectedProject?.getCurrencySymbol()}`,
            month: `${SHORT_MONTHS[it.month - 1]} ${it.year}`
        })));


    }, [currentUser, selectedProject]);


    return <div
        className={"col-sm-12 col-md-12  bg-white d-flex  flex-column justify-content-start mt-5"}>
        {/*row 1*/}

        <p className={"text_weight_700_20_px"}>Mes paiements à venir</p>
        <div className={"d-flex justify-content-between"}>

            {nextContributions && <TableContainer component={Paper} className={"w-sm-auto w-md-75"}>
                <div style={{ overflow: "scroll" }}>
                    <Table sx={{ minWidth: 300, minHeight: 200 }} size="small" aria-label="a dense table">
                        <TableHead className={"bg-light"}>
                            <TableRow>
                                {/*<TableCell></TableCell>*/}
                                <TableCell align="left" className={"text_weight_700_20_px"}>Mois</TableCell>
                                <TableCell align="left" className={"text_weight_700_20_px"}> Montant</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>{nextContributions.map((row) => (
                            <TableRow
                                className={"hover-row"}
                                key={row.uuid}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

                            >
                                <TableCell component="th" scope="row" className={"align-items-center"}>
                                    {`  ${row.month}`}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    {`${row.amount}`}
                                </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </div>
            </TableContainer>}
        </div>

    </div>
}