import {AddCircle} from "@mui/icons-material";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SavingsIcon from '@mui/icons-material/Savings';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {User} from "../../../Models/User";
import {AdminProjectContext} from "../../../contexts/AdminProjectContext";
import {AdminUserContext} from "../../../contexts/AdminUserContext";
import {Withdrawal} from "../../../interface/Withdrawal.interface";
import {ADMIN_VIEWS} from "../../../utils/constant";
import AdminAddProjectParticipantsModal from "../modal/AdminAddProjectParticipantsModal";
import AdminParticipantSettingModal from "../modal/AdminParticipantSettingModal";
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';


export default function AdminActiveProjectUsersView(props: {
    selectedMenu: Function,
}) {
    const {selectedProject} = useContext(AdminProjectContext)
    const {updateSelectedProjectParticipant, selectedProjectParticipant} = useContext(AdminUserContext)
    const [openSettingModal, setOpenSettingModal] = useState(false);


    const [dataMapped, setDataMapped] = useState<{
        withdrawals: Withdrawal[],
        uuid: string,
        share: number,
        firstname: string,
        withdrawalRank: string,
        lastname: string,
        upToDate: boolean

    }[]>()

    useEffect(() => {
        // console.log("*** sel", selectedProject)
        setDataMapped(selectedProject?.Users.map((user: User) => ({
            withdrawals: user.getUserWithdrawals(selectedProject!!),
            uuid: user.uuid,
            share: user.UserProject?.share || 0,
            withdrawalRank: user.UserProject!!.shareConfiguration!!.map(it => it.withdrawalRank).join(","),
            firstname: user.firstname,
            lastname: user.lastname,
            upToDate: user.assertUserIsUpToDate(selectedProject!!)
        })))
    }, [selectedProject]);

    const handleCashInClick = (row: {
        withdrawals: Withdrawal[],
        firstname: string,
        uuid: string,
        lastname: string,
        upToDate: boolean
    }) => {
        // console.log(selectedProject?.Users)
        updateSelectedProjectParticipant(selectedProject!!.Users.filter((it: User) => it.uuid === row.uuid)[0])
        props.selectedMenu(ADMIN_VIEWS.NEW_CASH_IN_VIEW)
    }

    const handleSendReminderClick = (row: {
        withdrawals: Withdrawal[],
        firstname: string,
        uuid: string,
        lastname: string,
        upToDate: boolean
    }) => {
    }

    const handleSettingClick = (row: {
        withdrawals: Withdrawal[],
        firstname: string,
        uuid: string,
        lastname: string,
        upToDate: boolean
    }) => {
        updateSelectedProjectParticipant(selectedProject!!.Users.filter((it: User) => it.uuid === row.uuid)[0])
        setOpenSettingModal(true)
    }

    const handleWithdrawOperationClick = (row: {
        withdrawals: Withdrawal[],
        firstname: string,
        uuid: string,
        lastname: string,
        upToDate: boolean
    }) => {
        console.log("** handleWithdrawOperationClick");
        updateSelectedProjectParticipant(selectedProject!!.Users.filter((it: User) => it.uuid === row.uuid)[0])
        props.selectedMenu(ADMIN_VIEWS.NEW_CASH_OUT_VIEW)
    }

    const [openNewParticipantModal, setOpenNewParticipantModal] = React.useState(false);

    return (
        <div>
            <div className={"d-flex justify-content-end mb-2"}>
                <button className={"btn btn-dark"} style={{backgroundColor: "black"}}
                    // onClick={() => props.selectedMenu(ADMIN_VIEWS.NEW_PARTICIPANT_VIEW)}
                        onClick={() => setOpenNewParticipantModal(true)}>
                    <AddCircle className={"me-1"}/>
                    Nouvel Adhésion
                </button>
            </div>

            <AdminAddProjectParticipantsModal setOpenModal={setOpenNewParticipantModal}
                                              openModel={openNewParticipantModal}></AdminAddProjectParticipantsModal>

            <AdminParticipantSettingModal openModal={openSettingModal} setOpenModal={setOpenSettingModal}
                                          participant={selectedProjectParticipant}/>


            {dataMapped && <TableContainer component={Paper} sx={{marginBottom: 16}}>
              <div style={{overflow: "scroll"}}>
                <Table sx={{minWidth: 450, minHeight: 200}} size="small" aria-label="a dense table">
                  <TableHead className={"bg-light"}>
                    <TableRow>
                        {/*<TableCell></TableCell>*/}
                      <TableCell align="left" className="fw-bolder">Nom et Prénom</TableCell>
                      <TableCell align="left" className="fw-bolder">Position</TableCell>
                      <TableCell align="left" className="fw-bolder">N° Parts</TableCell>
                        {/*<TableCell align="left" className="fw-bolder"> Statut</TableCell>*/}
                        {/* <TableCell align="left" className="fw-bolder"> Position</TableCell> */}
                      <TableCell align="left" className="fw-bolder"> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>{dataMapped.map((row) => (
                      <TableRow
                          hover
                          className='cursor-pointer'
                          key={row.uuid}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}>

                          <TableCell component="th" scope="row" className={"align-items-center"}>
                              <img src={"images/customer.png"} className={"me-1"} alt={""} width={"20px"}
                                   height={"20"}/>{`  ${row.firstname} ${row.lastname}`}
                          </TableCell>
                          <TableCell align="left">{`${row.withdrawalRank}`}</TableCell>
                          <TableCell align="left">{`${row.share}`}</TableCell>
                          {/*<TableCell align="left">*/}
                          {/*    <div className={"d-flex align-items-center"}>*/}
                          {/*        {!row.upToDate &&*/}
                          {/*            <Chip size={"small"} className={"m-2 p-3"} label={"Non à jour"}*/}
                          {/*                color={"error"}></Chip>}*/}
                          {/*        {row.upToDate &&*/}
                          {/*            <Chip size={"small"} className={"m-2 p-3"} label={"A jour"}*/}
                          {/*                color={"success"}></Chip>}*/}
                          {/*        {row.withdrawals.length > 0 && row.withdrawals.map(it => <Chip className={"m-2"}*/}
                          {/*            label={`Percu le ${it.date.toLocaleDateString("fr")}`}*/}
                          {/*            color={"success"}></Chip>)}*/}
                          {/*        {row.withdrawals.length == 0 &&*/}
                          {/*            <Chip size={"small"} className={"m-2 p-3"} label={`Non Percu`}*/}
                          {/*                color={"warning"}></Chip>}*/}
                          {/*    </div>*/}
                          {/*</TableCell>*/}
                          {/* <TableCell component="th" scope="row">{`${row.withdrawalRank}`}</TableCell> */}
                          <TableCell>
                                    <span title={"Enregistrer un paiement "}>
                                        <SavingsIcon className={"shadow-on-hover  rounded-circle p-1 m-1"}
                                                     sx={{height: 32, width: 32}}
                                                     color={"success"}
                                                     onClick={() => handleCashInClick(row)}></SavingsIcon>
                                    </span>

                              <span title={"Effectuer un retrait"}>
                                        <MonetizationOnIcon color={"primary"}
                                                            sx={{height: 32, width: 32}}
                                                            onClick={() => handleWithdrawOperationClick(row)}
                                                            className={"shadow-on-hover  rounded-circle p-1 m-1"}></MonetizationOnIcon>
                                    </span>
                              <span title={"Retirer"}>

                                        <RemoveCircleOutlineOutlinedIcon color={"error"}
                                                               sx={{height: 32, width: 32}}
                                                               onClick={() => handleSettingClick(row)}
                                                               className={"shadow-on-hover  rounded-circle p-1 m-1"}></RemoveCircleOutlineOutlinedIcon>
                                    </span>

                              {/* <span title={"Envoyer un rappel"}>
                                        <EmailIcon color={"info"} onClick={() => handleSendReminderClick(row)}
                                            className={"shadow-on-hover  rounded-circle  p-1 m-1"}></EmailIcon>
                                    </span> */}
                          </TableCell>
                      </TableRow>
                  ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>}
        </div>
    );
}
