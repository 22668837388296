import { CreateNewFolder, } from "@mui/icons-material";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useContext, useState } from "react";
import { AdminProjectContext } from "../../../contexts/AdminProjectContext";
import { Project } from "../../../Models/Project";
import { ADMIN_VIEWS, defaultProject } from "../../../utils/constant";
import AdminProjectEditingModal from '../modal/AdminProjectEditingModal';

export default function AdminProjectsTab(props: { selectedMenu: Function }) {
    const { projects } = useContext(AdminProjectContext)

    const [openModal,setOpenModal]=useState<boolean>(false)
    const [openedProject,setOpenedProject]=useState<Project>(new Project(defaultProject))

    const data = [
        {

            description: "Total",
            label: <span className={"text_weight_700_24_px"}>{projects?.length}</span>,
            classes: "text-success"
        },
        {

            description: "En cours",
            label: "-",
            classes: "text-success"

        },
        {

            description: "Terminé(s)",
            label: "-",
            classes: "text-success"

        },
    ]

    function handleRowClicked(row: Project) {
        //console.log("*** Row clicked");
        setOpenedProject(row)
        setOpenModal(true)
    
    }

    function handleNewViewClick(view: number) {
        return () => props.selectedMenu(view);
    }

    return (
        <div
            className={"row h-100 col-sm-12 col-md-12 col-lg-8 col-xl-9 bg-white d-flex justify-content-between align-items-sm-center align-items-md-start px-2 "}>


            {/*stats*/}
            <p className={"text_weight_700_20_px"}>Détails</p>
            <div className={"d-flex justify-content-between col-sm-12"}>
                {data.map((it,index) =>
                    <div className={`card_detail`} key={`l-${index}`}>
                        <p className={`${it.classes}`}>{it.label}</p>
                        <p>{it.description}</p>
                    </div>
                )}
            </div>

            <div className={"d-flex justify-content-end mb-2 mt-5"}>
                <button className={"btn btn-dark"} style={{ backgroundColor: "black" }}
                    onClick={handleNewViewClick(ADMIN_VIEWS.NEW_PROJECT_VIEW)}>
                    <CreateNewFolder className={"me-1"} />
                    Nouveau Projet
                </button>
            </div>

            <AdminProjectEditingModal openModal={openModal} setOpenModal={setOpenModal} openedProject={openedProject!!} setOpenedProject={setOpenedProject}></AdminProjectEditingModal>

            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 50, minHeight: 200 }} size="small" aria-label="a dense table">
                    <TableHead className={"bg-light "}>
                        <TableRow>
                            {/*<TableCell></TableCell>*/}
                            <TableCell className={"fw-bolder"} align="left">Nom</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Date de début</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Date de fin</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Cotisation mensuelle</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Montant par prise</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Status</TableCell>
                            <TableCell className={"fw-bolder"} align="left">Participants</TableCell>
                            {/*<TableCell className={"fw-bolder"} align="left">Fond de caisse</TableCell>*/}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects?.map((row, index) => (
                            <TableRow
                            onClick={(e) => handleRowClicked(row)}
                                hover
                                className='cursor-pointer'
                                key={`${index}`}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {/*<TableCell></TableCell>*/}
                                <TableCell component="th" scope="row">
                                    <img src={"images/task.png"} className={"me-1"} alt={""} width={"20px"}
                                        height={"20"} />{`${row.name}`}</TableCell>
                                <TableCell component="th"
                                    scope="row">{`${row.startDate.toLocaleDateString()}`}</TableCell>
                                <TableCell component="th"
                                    scope="row">{`${row.endDate.toLocaleDateString()}`}</TableCell>
                                {/*<TableCell component="th" scope="row">{`${row.penaltyPerDay}% (${row.penaltyAmountPerDay}€/jr)`}</TableCell>*/}
                                <TableCell component="th" scope="row"><span className="me-2">{`${row.contribution}€`}</span></TableCell>
                                {/*<TableCell component="th" scope="row">{`${row.dueDay}`}</TableCell>*/}
                                <TableCell component="th" scope="row">{`${row.withdrawalAmount}€`}</TableCell>
                                <TableCell component="th" scope="row">{`${row.status}`}</TableCell>
                                <TableCell component="th" scope="row">{`${row.Users.length}`}</TableCell>
                                {/*<TableCell component="th" scope="row">{`${row.cashFund}% (${row.cashFundAmount}€)`}</TableCell>*/}
                            </TableRow>
                        ))}
                    </TableBody>
                    {/*<TableFooter className={"bg-light"}>*/}
                    {/*    <TableRow>*/}
                    {/*       */}
                    {/*    </TableRow>*/}
                    {/*</TableFooter>*/}
                </Table>
            </TableContainer>
        </div>
    );
}
