import exp from "constants";

export const USER_STORAGE_KEY: string = "currentUser"
export const SELECTED_PROJECT_STORAGE_KEY: string = "selectedProjectUuid"
export const SAVED_SELECTED_MENU_STORAGE_KEY: string = "selectedMenuKey"
export const FULL_MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];
export const SHORT_MONTHS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
export const DAYS = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

export const ADMIN_VIEWS = {
    DASHBOARD_VIEW: 1,
    PROJECT_LIST_VIEW: 2,
    USER_LIST_VIEW: 3,
    NEW_PROJECT_VIEW: 4,
    NEW_USER_VIEW: 5,
    NEW_PARTICIPANT_VIEW: 6,
    NEW_CASH_IN_VIEW: 7,
    NEW_CASH_OUT_VIEW: 8,
    // USER_SETTINGS_VIEW: 9,
}

export const ROLES = {
    ADMIN: "ADMIN",
    USER: "USER"
}

export const defaultUser = {
    uuid: "",
    email: "",
    role: "",
    projects: [],
    isActivated: true,
    firstname: "",
    defaultProject: undefined,
    lastname: "",
    tokens: {accessToken: "", refreshToken: ""}
}
export const defaultProject = {
    uuid: "",
    status: "",
    participants: 0,
    name: "",
    startDate: new Date(),
    endDate: new Date(),
    penaltyAmountPerDay: 0,
    penaltyPerDay: 0,
    dueDay: 0,
    currency: "EUR",
    activated: false,
    contribution: 0,
    withdrawalAmount: 0,
    withdrawalDay: 0,
    cashFund: 0,
    cashFundAmount: 0,
    Users: [],
    Withdrawals: [],
    Penalties: [],
    TransactionIns: [],
    ProjectLines: [],
    UserProjects: []
}
export const EMAIL_REG_EXPRESSION = /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/

//****************************************** */
// export const VERSION = "local"
// export const BASE_URL: string = "http://localhost:300O"

export const VERSION = "prod"
export const BASE_URL: string = "https://ogu0lcsova.execute-api.eu-west-3.amazonaws.com"

//********************************************************/

export const LOGIN_URL = `${BASE_URL}/${VERSION}/auth/login`
export const USER_SELF_INFO_URL = `${BASE_URL}/${VERSION}/user/project/default`
export const USER_PROJECT_URL = `${BASE_URL}/${VERSION}/user/project/me`
export const PROJECT_LIST_URL = `${BASE_URL}/${VERSION}/projects`
export const USER_LIST_URL = `${BASE_URL}/${VERSION}/users`
export const REGISTRATION_URL = `${BASE_URL}/${VERSION}/auth/register`
export const NEW_PROJECT_URL = `${BASE_URL}/${VERSION}/project`
export const UPDATE_PROJECT_URL = (projectUuid: string) => `${BASE_URL}/${VERSION}/project/${projectUuid}`
export const UPDATE_USER_URL = (userUuid: string) => `${BASE_URL}/${VERSION}/user/${userUuid}`
export const REMOVE_PARTICIPANT_URL = (projectUuid: string, userUuid: string) => `${BASE_URL}/${VERSION}/project/${projectUuid}/user/${userUuid}`
export const NEW_CASH_IN_URL = `${BASE_URL}/${VERSION}/transaction`
export const NEW_CASH_OUT_URL = `${BASE_URL}/${VERSION}/withdrawal`
export const NEW_PARTICIPANT_URL = (projectUuid: string, userUuid: string, share: number, withdrawalRanks: number[] ) => `${BASE_URL}/${VERSION}/project/${projectUuid}/user/${userUuid}?share=${share}&withdrawalRanks=${withdrawalRanks.join(",")}`
export const CURRENCY: { label: string, symbol: string }[] = [
    {label: "USD", symbol: "$"},
    {label: "EUR", symbol: "€"},
    {label: "XOF", symbol: "CFA"},
    {label: "NGN", symbol: "₦"},
    {label: "GBP", symbol: "£"},
    {label: "RUB", symbol: "₽"},
    {label: "CAD", symbol: "$"},
    {label: "AUD", symbol: "$"},
    {label: "CHF", symbol: "CHF"},
    {label: "JPY", symbol: "¥"},
    {label: "CNY", symbol: "¥"},
    {label: "INR", symbol: "₹"},
    {label: "AED", symbol: "د.إ"},
    {label: "ZAR", symbol: "R"},
    {label: "KES", symbol: "KSh"},
    {label: "GHS", symbol: "GH₵"},
    {label: "EGP", symbol: "E£"},
    {label: "MAD", symbol: "د.م."},
    {label: "XPF", symbol: "CFP"},
    {label: "XAF", symbol: "FCFA"},
]

